import { EcouiProvider } from 'ecologital-ui-library';
import React, { Component } from 'react';

// Integrating UI Library's CSS.
import 'ecologital-ui-library/dist/ecologitalUILibrary.css';

import './EcouiItemsChecker.css';

// Helper Component to wrap UI Library's necessary things.
export default class EcouiItemsChecker extends Component {
	render() {
		const { children } = this.props;

		return (
			<EcouiProvider>
				<div className='EcouiItemsChecker'>{children}</div>
			</EcouiProvider>
		);
	}
}
